import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-neo-link',
    templateUrl: './neo-link.component.html',
    styleUrls: ['./neo-link.component.scss'],
    standalone: true,
})
export class NeoLinkComponent implements OnChanges {
    @Input() public to: string = '';
    @Input() public openNewPage: boolean = false;

    constructor(private router: Router) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['to']) {
            this.to = changes['to'].currentValue;
        }

        if (changes['openNewPage']) {
            this.openNewPage = changes['openNewPage'].currentValue;
        }
    }

    public navigate() {
        try {
            new URL(this.to);
            if (this.openNewPage) {
                const win = window.open(this.to, '_blank');
                win!.focus();
            } else {
                window.open(this.to, '_self');
            }
        } catch (e) {
            console.error(e);
            this.router.navigate([this.to]).then((r) => r);
        }
    }
}
